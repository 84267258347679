@import 'font';
@import 'dimensions';
@import './colors.scss';

$navbar-item-fontsize: 16px;
$cart-icon-size: 17px;//$navbar-item-fontsize - 1px;
$padding-horizontal-content: 17.6%;

body {
  font-family:AvenirNext;
}

a {
  text-decoration: none;
  color: inherit;
}

h2 {
  font-family: AvenirNext;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
  text-align: left;
  color: #656565;
  //color: #02005c;

  @media screen and (max-width: 768px){
    padding-left: 0px;
  }
}

input {
  // min-width: 400px;
  padding: 16px 20px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-width: 0;
  background-color: #fff;
  font-family: AvenirNext;
  font-size: 14px;
}

input:focus {
  outline: none !important;
}

#main {
  display: flex;
  flex-direction: column;
  //padding-top: 78px;
}

#logo {
  width: 60px
}

#navbar-icon {
  width: $cart-icon-size;
  height: $cart-icon-size;
  color: black;
  margin-left: 3px;
}

#navbar-icon:hover {
  cursor: pointer
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently*/
}

.numbered-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 25px; 
}

.cart-notify {
  position: absolute;
  right: 4px;
  top: 5px;
  height: 9px;
  width: 9px;
  text-align: center;
  font-size: 8px;
  color: white;
  font-weight: 600;
}

.popup {
  cursor: pointer;
  padding: 30px;
  background-color: $fucsia-color;
  color: white;
  font-size: 15px;
  font-family: AvenirNext;
      margin-right: auto;
    margin-left: auto;
  position: fixed;
  display: flex;
  justify-content:center;
  align-items:center;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
}

.popup-visible {
  animation: hide 1500ms 2;
  animation-direction: alternate;
  // transform: translateY(0px);
  // transition: transform 200ms;
}

// .popup-hidden {
//   bottom: 0;
//   left: 0;
//   right: 0;
//   transform: translateY(100%);
//   transition: all 200ms;
//   // animation: hide 2000ms;
// }

@keyframes hide {
  0%{
    opacity: 0;
  }
  
  1%{
    opacity: 1;
    transform: translateY(100%);
  }

  20% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }

}

.divisor {
  height: 0.5px;
  width: 100%;
  background-color: rgba(101, 101, 101, 0.17);
  margin: 20px 0;
}

.navbar-mobile {
  visibility: visible;
}




.sliding-panel-container {
  background-color: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30%;
  max-width: 300px;
  min-width: 250px;
  z-index: 10;
  // padding-left: 35px;
  padding-top: 30px;
  color: $fucsia-color;

  .sliding-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 25px;

    .sliding-panel-header-close {
      cursor: pointer;
    }
  }

  .sliding-panel-body {
    margin-top: 20px;
    //margin-left: 35px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    .sliding-panel-item {
      padding: 15px 0;
      padding-right: 10px;
      padding-left: 35px;
      transition: all 200ms;
      cursor:pointer;
    }
    // .sliding-panel-item:hover {
    //   transform: scale(1.06);
    // }
    .sliding-panel-item.active {
      font-weight: 600 !important;
    }
  }
}

.sliding-panel-visible {
  box-shadow: 3px 0 9px 0 rgba(0, 0, 0, 0.16);

  transform: translateX(0);
  transition: transform 200ms;
}

.sliding-panel-hidden {
  box-shadow: none;

  transform: translateX(-100%);
  transition: transform 200ms;
}

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 16px 17px 0px rgba(0, 0, 0, 0.05);
  transition: transform 200ms;
  z-index: 5;
}

.navbar-content {
  width: $page-width;
      margin-right: auto;
    margin-left: auto;

  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
  padding: 23px 0px;
}


.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-mobile {
  display: flex;
  align-items: center;
}

.header-banner {
  background-color: $fucsia-color;
  color: $white-color;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
  //transition: all 150ms;

  &.active {
    height: 0;
    padding: 0;
  }
}

.header-banner-close {
  cursor: pointer;
  padding: 0 20px;
}

.image-hover { 
  opacity: 0;
  transition: all 250ms;
}

.image-hover:hover {
  opacity: 1;
}


@media screen and (min-width: 1024px) {
  
  #margined {
    width: $page-width;
    margin-right: auto;
    margin-left: auto;
    overflow-x: visible;
    //padding: 0 $padding-horizontal-content;
  }
  


  .navbar-container.white {
    color: #fff;
  }

  .navbar-container.grey {
    color: #656565;
  }

  .navbar-container.pink {
    color: #cc4ba4;
  }
  
  .navbar-dynamic {
    position: fixed;
    transition: transform 200ms;
    // border-radius: 100px;
    padding: 26px 58px;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    color: #cc4ba4;
    z-index: 1000;
  }

  .navbar-right {
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .navbar-item {
    font-family: "AvenirNext";
    font-size: $navbar-item-fontsize;
    font-weight: 300;
    margin-left: 55px;
    align-items: center;
    display: flex;
    align-items: center;
    color: $fucsia-color;
  }

  .navbar-item.active {
    font-weight: 600 !important;
  }

  .navbar-logo { 
    height: 35px;
    cursor: pointer;
  }

  .navbar-left,.navbar-mobile {
    display: none;
  }

}


@media screen and (max-width: 1023px) {
  #main {
    //padding-top: 58px;
  }
  #margined {
    margin: 0 6%;
    overflow-x: visible;
  }

  .cart-notify {
    color: white;
    right: 10px;
    top: 11px;
    height: 9px;
    width: 9px;
  }

  #navbar-icon {
    width: 21px;
    padding: 4px;
    margin-left: 0;
  }

  .navbar-right,.navbar-dynamic {
    display: none !important;
  }

  .navbar-logo { 
    height: 35px;
    cursor: pointer;
  }

  .navbar-content {
    width: auto;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    padding: 15px 0px;
    margin: 0px 20px;
  }

  .navbar-left .hamburger-icon {
    width: 20px;
    padding: 4px;
    cursor: pointer;
  }
}

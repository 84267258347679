//diff between normal and hover (on buttons): 0xA0504
//diff between normal and active (on buttons): 0x110908
$pink-color: #ffcbd1;
$pink-color-hover: #F5C6CD;
$pink-color-active: #EEC2C9;

$fucsia-color: #cc4ba4;
$fucsia-color-hover: #c246a0;
$fucsia-color-active: #bb429c;
$fucsia-color-unselected: #e782c7;

$orange-color: #ff6948;
$orange-color-hover: #f56444;
$orange-color-active: #ee6040;

$grey-color: #656565;
$grey-color-active: #65656517;
$grey-color-unselected: #65656566;

$greylight-color: #f2f2f2;
$greylight-color-hover: #E8EDEE;
$greylight-color-active: #E1E9EA;

$white-color: white;
$white-color-active: #EEF6F7;
$white-color-hover: #f5fafb;

$darkgrey-color: #606060;

$black-color: #2b2b2b;
$error-color: #f13333;
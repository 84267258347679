@import 'colors';

.skeleton {
  background-color: #dddddd;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.skeleton::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #eeeeee, rgba(255, 255, 255, 0));
  transform: translateX(-100%);
  animation: loading 1.2s infinite;
  position: absolute;
}

@keyframes loading {
  100% {
    transform: translateX(100%)
  }
}
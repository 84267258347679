@import 'colors';

.checkout-input-container {
  display:flex;
  flex-direction: column;
  flex:1;
  min-width: 0;
  
}
.checkout-input,
.StripeElement {
  min-width: 0;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  padding: 16px 13px;
  box-shadow: none;//0 1.5px 6px 0 rgba(0, 0, 0, 0.16);
}
#input-address {
  flex: 2.05;
}

.checkout-input-container.left {
  margin-right: 5px;
}
.checkout-input-container.middle {
  margin-left: 5px;
  margin-right: 5px;
}
.checkout-input-container.right {
  margin-left: 5px;
}

.error-msg {
  font-family: AvenirNext;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.36px;
  color: $error-color;
}
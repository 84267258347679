@import 'colors';
@import 'dimensions';

.footer-container {
  margin-top: 40px;
  display: flex;
  background-color: #f2f2f2;
  width: 100%;

  .footer-body {
    width: $page-width;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
}

.footer-main {
  display: flex;
  margin-top: 38px;
  padding-bottom: 40px;
  justify-content: space-between;
}

.footer-main-left {

}

.footer-main-right {
  display: flex;
}

.footer-topic {
  margin-left:60px;
}

.footer-topic-title {
  font-weight: 600;
  font-size: 15px;
  color: $grey-color;
  margin-bottom: 10px;
}

.footer-topic-element {
  font-weight: 300;
  font-size: 12px;
  color: $grey-color;
  a:hover {
    text-decoration: underline !important;
  }

}

.footer-lastline-container {
  font-size: 12px;
  font-weight: 300;
  color: $grey-color;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 39px;
  margin-top: 40px;
  text-align: center;
}

// .footer-copyright,
// .footer-developed-by {
//   flex: 1;
//   font-size: 12px;
//   font-weight: 300;
//   color: $grey-color;
// }

// .footer-developed-by {
//   text-align: end;
// }

@media screen and (max-width: 1023px) {
  .footer-container .footer-body {
      width: 90%;
      margin: auto;
    
  }
}

@media screen and (max-width: 1023px) {
  .footer-container .footer-body {
      width: 85%;
      margin: auto;
  }

  .footer-main {
    flex-direction: column;
  }

  .footer-main-right {
    flex-direction: column;
  }

  .footer-topic {
    margin-left: 0;
    margin-top: 30px;
  }
}

@import 'colors';

.button {
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 17px 10px;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 200px;

  //margin-right: auto;
  transition: all 100ms;

  &.disabled {
    opacity: 0.4;
  }
}

.button-orange,
.button-fucsia,
.button-pink,
.button-white,
.button-grey {
  cursor: pointer;
  transition: all 100ms;
}

/*******************
ORANGE
********************/
.button-orange {
  background-color: $orange-color;
}

.button-orange:hover {
  background-color: $orange-color-hover;
}

.button-orange:active {
  background-color: $orange-color-active;
}

/*******************
PINK
********************/
.button-pink {
  background-color: $pink-color;
}

.button-pink:hover {
  background-color: $pink-color-hover;
}

.button-pink:active {
  background-color: $pink-color-active;
}

/*******************
FUCSIA
********************/
.button-fucsia {
  background-color: $fucsia-color;
}

.button-fucsia:hover {
  background-color: $fucsia-color-hover;
}

.button-fucsia:active {
  background-color: $fucsia-color-active;
}

/*****************************
WHITE
****************************/
.button-white {
  background-color: $white-color;
  color: $fucsia-color;
}

.button-white:hover {
  background-color: $white-color-hover;
}

.button-white:active {
  background-color: $white-color-active;
}

/*****************************
GREYLIGHT
****************************/
.button-greylight {
  background-color: $greylight-color;
}

.button-greylight:hover {
  background-color: $greylight-color-hover;
}

.button-greylight:active {
  background-color: $greylight-color-active;
}


.button-pink,
.button-fucsia,
.button-orange{
  font-weight: 600;
  cursor: pointer;
  color: white;
}

.button-pink.disabled,
.button-orange.disabled,
.button-white.disabled,
.button-fucsia.disabled,
.button-greylight.disabled {
  opacity: .2; 
  pointer-events: auto; 
}

.button-pink:active,
.button-fucsia:active,
.button-orange:active,
.button-white:active,
.button-greylight:active {
  transform: scale(0.99);
}
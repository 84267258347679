@import './colors';
@import './candorlanae_page';

.summary-container {
  width: 270px;
  height: auto;
  padding: 15px 12px;
  font-family: AvenirNext;
  border-radius: 14px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .summary-title {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.36px;
    color: #2b2b2b;

    margin-bottom: 28px;
  }

  .summary-body {
    font-size: 14px;
    letter-spacing: 0.36px;
    color: #2b2b2b;
    
    .summary-body-element {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .summary-body-element-name {
        font-size: 14px;
        text-overflow: ellipsis;

        &.total {
          font-size: 15px;
        }
      }

      .summary-body-element-total {
        font-size: 15px;
        font-weight: 600;
      }
  
      .summary-body-element-price {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  
}

.cartitem-container {
  padding: 8px;
  padding-right: 12px;
  border-radius: 14px;
  box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  margin-bottom: 12px;

  .cartitem-img-container {
    flex: 2.5;
    border-radius: 5px;
    margin-right: 17px;
    min-width: 100px;
    cursor: pointer;
  }

  .cartitem-img {
    border-radius: 10px;
    //box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  }

  .cartitem-body {
    display: flex;
    flex: 10;
    flex-direction: column;
    font-family: AvenirNext;
    justify-content: space-around;
    color: #2b2b2b;
    text-align: left;

    .cartitem-body-info {

      .cartitem-body-name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: .5px;
        margin-bottom: 8px;
        // margin-top: 3px;
      }

      .cartitem-body-remove-text {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 8px;
        cursor: pointer;
      }

      .cartitem-body-remove-icon {
        height: 12px;
        padding: 5px;
        border-radius: 100px;
        align-self: flex-start;
        //box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 16%);
        cursor: pointer;
      }

      .cartitem-body-description {
        font-size: 12px;
        letter-spacing: 0.4px;
        color: $black-color;
      }
    }

    .cartitem-body-details {
      display: flex;
      // align-items: center;
      font-size: 12px;
      flex-direction: row;

      .cartitem-body-details-container {
        display: flex;
        flex-direction: column;
        font-size: 11px;
        color: $grey-color;
        margin-right: 20px;

        .cartitem-body-color {
          display: flex;
          align-items: center;
          flex-direction: row;
          letter-spacing: 0.4px;
          color: $black-color;
    
          .cartitem-body-color-circle {
            height: 10px;
            width: 10px;
            margin-right: 5px;
            border-radius: 100px;
          }
        }
  
        .cartitem-body-size {
          // flex:1;
          color: $black-color;
        }
      }

    }


    .cartitem-body-price {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cartitem-body-price-value {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.6px;
      }

      .cartitem-body-price-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .cartitem-body-price-remove,.cartitem-body-price-add {
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.6px;
          cursor: pointer;
          padding: 5px;
        }

        .cartitem-body-price-remove.disable {
          cursor: default;
          color: #2b2b2b44;
        }
  
        .cartitem-body-price-qty {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.6px;
          margin: 0 8px;
        }
      }

    }
  }
}

.gotopayment-button {
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $fucsia-color;
  text-align: center;
  padding: 17px;
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: #fff;
}

.no-element-in-cart {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  flex-direction: column;
  align-items: center;
  font-family: AvenirNext;
  font-size: 17px;
  font-weight: 600;
  color: $fucsia-color;
  background-color: white;
  padding: 30px;
  text-align: center;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.go-to-shop {
  //min-width: 0px;
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 17px 50px;
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-top: 33px;
  margin-left: auto;
  margin-right: auto;

  //margin-right: auto;
  transition: all 100ms;

  &.disabled {
    opacity: 0.4;
  }
}

.cart-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.cart-body-container {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.cart-body-products {
  flex: 1;
  margin-right: 30px;
}


@media screen and (max-width: 1024px) {

  .cart-body {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
  }

}

@media screen and (max-width: 768px) {

  .cart-body {
    width: auto;
    margin: 0 20px;
  }

  .cart-body-container {
    flex-direction: column;
    padding-top: 0px;
  }

  .cart-body-products {
    margin-right: 0px;
  }

  .cartitem-container {
    padding: 7px;
    margin-bottom: 13px;
    .cartitem-body {
      flex: 8;

      .cartitem-body-info {
        margin-bottom: 8px;
        padding-top:3px;

        .cartitem-body-name {
        }
        .cartitem-body-description {
          font-size: 12px;
        }

        .cartitem-body-remove-text {
          font-size: 14px;
        }
      }
      .cartitem-body-details {
        padding-bottom: 12px;
        flex-direction: row;
        .cartitem-body-color {
           font-size: 11px;
        }
        .cartitem-body-size {
          font-size: 11px;
        }
      }

      .cartitem-body-price {
        .cartitem-body-price-value {
          font-size: 16px;
        }

        .cartitem-body-price-container {
          .cartitem-body-price-qty {
            width: auto;
            font-size: 15px;
          }
          .cartitem-body-price-add,.cartitem-body-price-remove{
            font-size: 14px;
          }
        }
      }
    }

    .cartitem-img-container {
      flex:2;
      margin-right: 12px;
      min-width: 115px;
    }
  }


  .cartitem-body-price-value {
    font-size: 16px;
  }

  .summary-container {
    width: auto;
    padding: 15px 12px;
  }
  
}

@media screen and (max-width: 425px) {

  .no-element-in-cart {
    //margin: 0 10px;
  }

  .cart-body {
    width: auto;
    margin: 0 18px;
  }

  .cart-body-container {
    flex-direction: column;
    padding-top: 0px;
  }

  .cart-body-products {
    margin-right: 0px;
  }

  .cartitem-container {
    padding: 7px;
    margin-bottom: 23px;
    .cartitem-body {
      flex: 8;

      .cartitem-body-info {
        margin-bottom: 8px;
        padding-top:3px;

        .cartitem-body-name {
        }
        .cartitem-body-description {
          font-size: 12px;
        }

      }
      .cartitem-body-details {

        .cartitem-body-color {
           font-size: 11px;
        }
  
        .cartitem-body-price {
          .cartitem-body-price-value {
            font-size: 16px;
          }
  
          .cartitem-body-price-container {
            .cartitem-body-price-qty {
              width: auto;
              font-size: 15px;
            }
            .cartitem-body-price-add,.cartitem-body-price-remove{
              font-size: 14px;
            }
          }
        }
      }
    }

    .cartitem-img-container {
      flex:4;
      margin-right: 12px;
      // min-width: 115px;
    }
  }


  .cartitem-body-price-value {
    font-size: 16px;
  }

  .summary-container {
    width: auto;
  }
  
}


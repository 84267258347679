@font-face {
  font-family: "AvenirNext";
  font-weight: 300;
  src: url("../assets/fonts/AvenirNext-Regular-08.ttf")
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 500;
  src: url("../assets/fonts/AvenirNext-Medium-06.ttf")
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 600;
  src: url("../assets/fonts/AvenirNext-DemiBold-03.ttf")
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 700;
  src: url("../assets/fonts/AvenirNext-Bold-01.ttf")
}
@import 'dimensions';

.notfound-body {
  width: $page-width;
  margin-left:auto;
  margin-right: auto;
}

@media screen and (max-width: 1023px) {
  .notfound-body {
    width: 95%;
    margin-left:auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .notfound-body {
    width: 90%;
    margin-left:auto;
    margin-right: auto;
  }
}
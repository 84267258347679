@import './colors';
@import './dimensions';

.product-slides {
  position: relative;
  flex: 1;
  margin-right: auto;
  max-width: 527px;
  max-height: 700px;
  height: 100%;
  // width: 100%;
  // flex:1;
  margin-right: 25px;
  // margin-left: 25px;
}

.product-image-container {
  flex: 1;
  // position: relative;
  // margin-right: 20px;
  max-height: 700px;
  // min-width: 100%;

  .product-image-style {
    border-radius: 4px;
  }

  // .product-image {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   object-fit: cover;
  // }
}

.product-arrow {
  height: 35px;
  position: absolute;
  top: calc(50% - 34.5px);
  bottom: 50%;
  z-index: 3;
  padding: 17px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 200ms;

  &.left {
    left: 0;
    margin-left: 10px;
  }

  &.right {
    margin-right: 10px;
    right: 0;
  }
}

.product-arrow:hover {
  background-color: #ffffff28;
}

.product-body {
  width: $page-width;
  margin-left: auto;
  margin-right: auto;

  .product-body-main {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;

    .product-info-container {
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      font-family: AvenirNext;
      flex: .5;
      max-width: 300px;
      // width: 300px;

      .product-info-title {
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0.76px;
        text-align: left;
        color: #2b2b2b;
        margin-bottom: 10px;
      }

      .product-info-price {
        font-size: 21px;
        font-weight: 600;
        letter-spacing: 0.44px;
        color: #2b2b2b;
        margin-bottom: 35px;
      }

      .product-info-colors {
        display: flex;
        flex-direction: row;
        margin-bottom: 7px;
      }

      .product-info-color {
        height: 10px;
        width: 10px;
        border-radius: 100px;
        margin-right: 7px;
        // margin-bottom: 4px;
        cursor: pointer;
      }

      .product-info-color-name {
        font-size: 11px;
        font-weight: 500;
        color: $grey-color;
        margin-bottom: 20px;
      }


      .product-info-details-container {
        margin-bottom: 25px;

        .product-info-size-container {

          .product-info-size {
            margin-bottom: 6px;

            .product-info-size-modal-content {
              transition: all 200ms;
            }
          }

          .product-info-size-list {
            display: flex;

            // .product-info-size {
            //   font-size: 17px;
            //   margin-right: 4px;
            //   border-radius: 100px;
            //   font-weight: 600;
            //   color: $black-color;
            //   padding: 4px;
            //   cursor: pointer;
            // }

            .product-info-size-unselected {
              font-size: 17px;
              margin-right: 4px;
              border-radius: 100px;
              font-weight: 600;
              color: $grey-color-unselected;
              padding: 4px;
              cursor: pointer;
            }
          }

        }

      }

      .product-info-addtocart {
        //margin-right: auto;
        text-align: center;
        border-radius: 14px;
        font-size: 14px;
        padding: 16px 30px;
        margin-bottom: 15px;
        // background-color: #ff6948;
        color: #fff;
      }

      .product-unavailable {
        font-size: 12px;
        color: #df1111;
        margin-left: 0px;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .product-time-to-delivery {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 600;
        color: $black-color;
      }

      .product-info-description {
        font-size: 13px;
        // max-height: 76px;
        margin-bottom: 30px;

        // overflow: scroll;
        font-weight: 300;
        color: $black-color;
      }
    }
  }

}

.product-otherimage-body {
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  min-width: 70px;

  .product-otherimage-container {
    cursor: pointer;
    margin-bottom: 14px;

    .product-otherimage-unselected {
      opacity: .6;
    }
  }
}

.product-features-body {
  display: flex;
  color: $black-color;

  .product-features-container {
    flex: 1;

    .product-features-title {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 12px;

      &.margined {
        margin-top: 25px
      }
    }

    .product-features-description {
      font-size: 13px;
      font-weight: 300;

      ul {
        padding-inline-start: 20px;

        li {
          margin-bottom: 5px;
        }
      }

      .product-features-description-element {
        margin-bottom: 5px;
      }
    }
  }


}

.product-info-color-container {}

.product-info-details-title {
  color: $black-color;
  font-size: 13px;
}

.product-info-color-title {
  margin-bottom: 6px;
}

.product-info-select {
  // padding: 8px 5px;
  font-size: 13px;
  letter-spacing: 0.8px;
  font-family: AvenirNext;
  color: $black-color;
  border-radius: 3px;
  border-width: 1.5px;
  border-color: $black-color;
  width: 100%;
}

.product-info-color-option-container,
.product-info-size-option-container {
  display: flex;
  align-items: center;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  cursor: pointer;
}

.product-info-size-container {
  margin-top: 20px;
}

.product-info-color-option-container:hover,
.product-info-size-option-container:hover {
  background-color: $white-color-active;
}

// .product-otherimage-body {
//   width: 85%;
//   margin:auto;
//   display: flex;
//   flex-direction: row;
//   margin-top: 33px;
//   overflow: hidden;
// }

.product-qualities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-size: 13px;

  color: $black-color;
}

.product-quality-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  background-color: #d3effd;
  border-radius: 30px;
  margin-right: 5px;
  padding: 5px 0;
  padding-left: 8px;
  padding-right: 16px;
  // width: min-content;
}

.product-customer-request-container {
  display: flex;
  flex-direction: column;
  background-color: $greylight-color;
  padding: 10px 13px;
  padding-bottom: 13px;
  border-radius: 5px;
  color: $black-color;
  margin-bottom: 18px;
}
.product-customer-request-title {
  font-size: 15px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 18px;
  color: $fucsia-color;
}
.product-customer-request-description {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
}

.product-customer-request-ttd {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.product-customer-request-input-container {
  flex: 5 !important;
  margin-right: 10px 
}

@media screen and (max-width: 1024px) {

  .product-body {
    width: 95%;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .product-image-container {
    margin-right: 0;
    max-width: none;
    max-height: none;

    .product-image-style {
      border-radius: 0;
    }
  }

  .product-arrow {
    display: none;
  }

  .dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;

    .dot {
      height: 12px;
      width: 12px;
      border-radius: 100px;
      background-color: rgba(253, 253, 253, 0.377);
      border: 1px solid #bbbbbb;
      margin: 0 8px;
      box-sizing: border-box;

      &.active {
        background-color: rgba(80, 80, 80, 0.712);
      }
    }
  }

  .product-body {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    .product-body-main {
      margin-top: 0;

      flex-direction: column;

      .product-slides {
        margin-left: 0px;
        max-width: none;
        margin-right: 0;
        max-height: none;
      }


      .product-info-container {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 15px;
        max-width: none;

        .product-info {
          margin-bottom: 17px;

          .product-info-details-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
          }

          .product-info-title {
            margin-bottom: 8px;
          }

          .product-info-price {
            margin-bottom: 15px;
          }

          .product-info-color-container {
            flex: 1;
            margin-right: 6px;
          }

          .product-info-size-container {
            flex: 1;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .product-otherimage-body {
    display: none;
  }

  .product-features-body {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;

    .product-features-container {
      margin-bottom: 20px
    }
  }

  // .product-features-body {
  //   width: 90%;
  // margin-right: auto;
  // margin-left: auto;
  //   display: flex;
  //   color: $black-color;

  //   .product-features-container {
  //     flex:1;

  //     .product-features-title {
  //       font-size: 15px;
  //       font-weight: 600;
  //       margin-bottom: 12px;
  //     }

  //     .product-features-description {
  //       font-size: 13px;
  //       font-weight: 300;

  //       ul {
  //         padding-inline-start: 20px;
  //         li {
  //           margin-bottom: 5px;
  //         }
  //       }

  //       .product-features-description-element {
  //       }
  //     }
  //   }


  // }
}

@media screen and (max-width: 470px) {

  // .product-body .product-slides .product-image-container {
  //     aspect-ratio: .75;
  // }
  .product-body .product-info-container .product-info-addtocart {
    margin-right: 0;
  }

  .product-body .product-body-main .product-info-container .product-info .product-info-details-container {}

  .product-body {

    .product-body-main {
      .product-info-container {
        .product-info {
          .product-info-details-container {
            flex-direction: column;
          }

          .product-info-color-container {
            flex: 1;
            margin-right: 0;
            margin-bottom: 13px;
          }

          .product-info-size-container {
            flex: 1;
            margin-top: 0;
            margin-bottom: 6px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    //box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  // 1% {
  //   transform: scale(0.96) translateY(10px);
  //   opacity: 0;
  //   box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  // }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    //box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  border: none;
  width: auto;
  border-radius: 8px;
  font-size: 12px;
  padding: 15px 20px;
  padding-right: 40px;
  font-weight: 300;
  -webkit-animation: anvil 100ms linear normal;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ul {
    padding-inline-start: 20px;
    margin: 8px 0 0 0;
  }

  .modal-title {
    padding-bottom: 8px;
  }

  .modal-list-element {
    padding-bottom: 3px;
  }
}
@import './dimensions';
@import './colors';

$margin-between-categories: 40px;

.panel {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  background-color: #f7f7f7;
  overflow: scroll;
  z-index: 100;
  padding: 30px 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-family: AvenirNext;
    font-size: 16px;
    font-weight: 500;

    text-align: left;
    color: #656565;
  }
}

.panel-visible {
  transform: translateX(0px);
  transition: transform 200ms ease-in-out;
}

.panel-hide {
  transform: translateX(110%);
  transition: transform 200ms ease-in-out;
}

.filter-container {
  padding: 0 30px;
  border-radius: 35px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: AvenirNext;
  font-size: 15px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #656565;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .filter-icon {
    height: 13px;
    margin-right: 15px;
  }
}

.panel-h.hide {
  height: 0;

  //transform: scaleY(0);
  transition: all 300ms ease-in-out;
  overflow: hidden;

  background-color: grey;
}

.panel-h.visible {
  background-color: grey;

  //transform: scaleY(1);
  height: 100px;
  transition: all 300ms ease-in-out;
}


.shop-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;

  display: flex;
  margin-top: 30px;
}

.shop-category-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.shop-category-container {
  flex:1;
  //width: 100% * 0.3;
}

.shop-category-container {
  margin-left: $margin-between-categories;
  margin-right: $margin-between-categories;
}

.shop-category-container:last-child,
.shop-category-container:first-child { 
  margin: 0
}

.items {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: center;
}

.items-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  //margin: 0 5px;
}

// .items {
//   display: grid;
//   grid-template-columns: 245px 245px 245px 245px;
//   grid-row-gap: 20px;
//   // flex-direction: row;
//   // justify-content: space-between;
//   margin-top: 60px;
// }

.item-card {
  // width: (($page-width - ($marginCard * ($numCard - 1))) / $numCard);
  //margin-right: $marginCard;
  border-radius: 20px;
  background-size: cover;
  font-size: 0px;
  position: relative;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
}

.item-card-title {
  position: absolute;
  width: 150px;
  text-overflow: ellipsis;
  left: 25px;
  top: 40px;
}

.item-card-price-and-cart {
  position: absolute;
  left: 25px;
  bottom: 30px;
}

.item-card:hover {
  cursor: pointer;
  font-family: AvenirNext;
  font-size: 17px;
  font-weight: 500;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, rgba(196, 38, 38, 0.84), #000000cc), url('../../src/assets/background/welcome.png');
  color: #e0e0d0;
}

.item-card:hover .item-card-add2cart {
  border-radius: 14px;
  font-size: 14px;
  padding: 13px 20px;
  margin-top: 5px;
  background-color: #ff6948;
}

.item-card:hover .item-card-add2cart:hover,.item-card-add2cart:focus  { 
  background-color: #f56444;
}

.item-card:hover .item-card-add2cart:active { 
  background-color: #ee6040;
  transform: scale(0.985);
}

.item-card-last {
  margin-right: 0px;
  //margin-left: $marginCard;
}

.item-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  // height: 100%;
  // aspect-ratio: 0.77;
  // margin-right: 10px;
  // margin-bottom: 5px;
  cursor:pointer;
  border-radius: 7px;
  padding: 5px 5px;
  padding-bottom: 7px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  transition: all 200ms;
}

.item-info {
  margin-left: 2%;

  .item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 6px;
    font-size: 14px;
  }

  .item-description {
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .item-price {
    font-weight: 600;
    font-size: 15px;
    color: $grey-color;
    margin-bottom: 3px;
  }
}

.load-other-products-container {
  display: flex;
  margin-top: 40px;
  // text-align: center;
  
  .load-other-products {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    color: $black-color;
    font-size: 13px;
  }

  .load-other-btn {
    // margin: auto;
    margin-top: 10px;
    padding: 12px 16px;
    border-radius: 5px;
    font-size: 12px;

    font-weight: 600;
    //background-color: #ff6948;
    color: $black-color;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
    text-align: center;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
}

.category-image {
  transition: all 350ms;
}

.category-image:hover {
  transform: scale(1.04);
}

@media screen and (max-width: 1024px) {

  .shop-body {
    width: 95%;

    flex-direction: column;
  }

  .shop-category-body {
    width: 95%;

    flex-direction: column;
  }

  .shop-category-container:last-child,
  .shop-category-container:first-child,
  .shop-category-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin-between-categories + 10px;
    width: 90%;
    max-width: 500px;
  }

}

// @media screen and (max-width: 768px) {

//   .shop-body {
//     width: 512px;
//   }

// }

// @media screen and (max-width: 513px) {

//   .shop-body {
//     width: auto;
//     //margin: 0 10px;
//   }

// }
@import './colors';
@import 'dimensions';

p {
  font-size: 14px;
  color: $grey-color;
}

.accepted-thanks {
  margin-top: 30px;
}

.result-payment-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;
}


@media screen and (max-width: 1024px) {
  
  .result-payment-body {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    }
  
}

@media screen and (max-width: 768px) {
  
  .result-payment-body {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    }
  
}


@media screen and (max-width: 425px) {
  
  .result-payment-body {
    margin-right: auto;
    margin-left: auto;
        margin: 0 10px;
  }
  
}
@import 'font';
@import 'colors';
@import 'candorlanae_page.scss';
@import 'dimensions';

::placeholder {
  font-family: AvenirNext;
  font-size: 14px;
  color: #bcbcbc;
}

.home-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.home-category-container {
  margin-top: 25px;
  margin-bottom: 5px;
  //margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-category-title {
  font-size: 18px;
  font-weight: 500;
  color: $black-color;
  letter-spacing: 0.2px;
}

.welcome-container {
  // display: flex;
  // flex-direction: row;
  // position: relative;
  // background-color: linear-gradient(115deg, $pink-color, rgb(211, 68, 166));
  // background-size: cover;
  padding-bottom: 12px;
  //background-image: url('../assets/background/welcome2.jpg');
  // background-image: linear-gradient(115deg, $pink-color, rgb(211, 68, 166));  

  background-image: linear-gradient(96deg, #f7a5cf, #ff50ee);//rgb(211, 68, 166));

  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 95%);

}

.welcome-bg {
  // height: 100%;
  // width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  // aspect-ratio: 1.9;
  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 95%);

}

.welcome-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.welcome-msg-container-desktop {
  display: block;
  position: absolute;
  left: calc(50% - #{$page-width * 0.5});
  padding: 5px 12px;
  color: #fff;//$fucsia-color;
  // background-color: #fff;
  border-radius: 10px;
  color: #fff;
}

.welcome-title {
  //width: 380px;
  // font-family: 'Dancing Script';
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 1.6px;
  // background:linear-gradient(
  //   96.97deg,
  //   #f7a5cf,
  //   #ff50ee);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
}

.welcome-subtitle {
  margin-top: 12px;
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .77px;
  line-height: 28px;
  width: 300px;
  border-radius: 10px;
  padding: 5px 10px;
  background:linear-gradient(
    96.97deg,
    #f7a5cf,
    #ff50ee);
}

.welcome-button {
  margin-top: 20px; 
  color: $black-color !important;
}

.welcome-msg-container-mobile {
  display: none;
}

.home-category {
  margin-bottom: 58px;
}

.answers {
  margin-top: 32px;

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .btns.hide {
    animation: hide-btns 200ms forwards;
  }

  .answer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 600;
    width: 140px;
    padding: 13px 20px;
    //background-color: #ff6948;
    margin: 0 10px;
    color: $fucsia-color;
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.17);
    text-align: center;
    cursor: pointer;
    transform: scale(1);
    transition: transform 200ms;
  }
  
  .answer-btn:hover { 
    transform: scale(1.05);
    transition: transform 200ms;
  }
  
  .answer-btn:active { 
    transform: scale(0.985);
    transition: transform 200ms;
  }
}

.home-category-scroll-icon {
  display: none;
}


.advices .advice-container.first {
  margin-left: 8px;
}

// .advices .advice-container.last {
//   margin-right: 8px;
// }
//remove scrollbar
.advices::-webkit-scrollbar {
  display: none;
}

.advices {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  padding:10px 0 10px 0;

  .advice-container {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    //max-width: $page-width/ 4;
    min-width: 200px;
    cursor:pointer;
    border-radius: 15px;
    // padding: 11px 8px;
    // padding-top: 8px;
    // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);  
    transition: all 200ms;
  }

  .advice-container:hover {
    // padding: 7px 5px;
    transform: scale(1.01);
    transition: all 200ms;
  }

  .advice-info {
    margin-left: 2%;

    .advice-name {
      margin-bottom: 6px;
      font-size: 16px;
    }

    .advice-description {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 12px;
    }

    .advice-price {
      font-weight: 600;
      font-size: 16px;
      color: $grey-color;
    }
  }


}

.add-to-cart {
  border-radius: 14px;
  font-size: 14px;
  padding: 13px 20px;
  margin-right: auto;
  box-shadow: 0px 6px 12px 0 rgba(0, 0, 0, 0.20);
}

.img-container {
  flex: 1.4;
  height: 280px;
}

.img {
  width: 100%;
  height: 100%;
  // flex:1.4;
  // // width: 200px;
  // aspect-ratio: 0.77;
  border-radius: 20px;
  object-fit: cover;
  // background-position: center;
  // background-size: cover;
  // background-color: pink;
  //box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 16%);
}

.arrow {
  position: absolute; 
  height: 100%;
  width: 50px;    
  display: flex;
  justify-content:center;
  align-items: center;
}

.arrow-back {
  opacity: 0;
  transition: opacity .3s linear;
  background: linear-gradient(to right, rgba(252, 252, 252, 0.753), transparent);
  
  left: 0;
}

.arrow-back.visible { 
  opacity: 1;
  transition: opacity .3s linear;
  background: linear-gradient(to right, rgba(252, 252, 252, 0.753), transparent);
  cursor: pointer;
}

.arrow-forward {
  opacity: 0;
  transition: opacity .3s linear;
  background: linear-gradient(to left, rgb(252, 252, 252), transparent);

  right: 0;
}

.arrow-forward.visible {
  opacity: 1;
  transition: opacity .3s linear;
  background: linear-gradient(to left, rgb(252, 252, 252), transparent);
  cursor: pointer;
}

.arrow-icon {
  height: 35px
}

.advice-scrollable {
  overflow: hidden;
  padding: 20px 10px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.advice-scrollable::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

// .circle-index {
//   height: 12px;
//   aspect-ratio: 1;
//   background-color: #ff69484d;
//   border-radius: 100px;
//   margin: 10px 7px;
//   cursor: pointer;
// }

// .circle-index.active {
//   background-color: #ff6948;
// }

.advice-index {
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 500;
  color: #6565654d;
  padding: 10px;
  cursor: pointer;
}

.advice-index.active {
  color: #656565;
  background-color: $grey-color-active;
  border-radius: 100px;
}

.question {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  margin-top: 10px;
  color: $fucsia-color;
}

.question-introduction {
  padding-top: 5px;
  font-family: AvenirNext;
  font-size: 14px;
  color: $grey-color;
  font-weight: 300;
}

.answer-thanks {
  font-family: AvenirNext;
  font-size: 15px;
  color: #656565;
  animation: show-thanks 600ms forwards;
}

@keyframes hide-btns {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes show-thanks {
  from {opacity: 0;}
  to {opacity: 1;}
}

.home-whoami {
  width: 100%;
  background-color: #fb78e0;
  padding: 50px 0;
  color: $white-color;
  
  .home-whoami-container {
    display: flex;
    width: $page-width;
    margin-right: auto;
    margin-left: auto;

    .home-whoami-img {
      display: block;
      width: 150px;
      height: 150px;
      border-radius: 1000px;
      background-image: url(../assets/background/ritaepaola.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 30px;
      background-color: $white-color;
      border: 2px solid $white-color;
    } 
  }
}


.home-whoami-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 14px;
}

.home-whoaim-description {
  font-size: 14px;
  font-weight: 300;
}


$margin-between-news: 4px;

.news-body {
  display: flex;
}

.news-first-container {
  flex: 1.55;
  margin-right: 5px;
}

.news-first-content {
  padding-top: 60%;
  border-radius: 7px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.news-other-container {
  display: flex;
  flex: 1;
  margin-left: 4px;
  flex-direction: column;
}

.news-other-content {
  display: flex;
  flex: 1;
  align-items: flex-end;
  background-color: red;
  border-radius: 7px;
  margin: $margin-between-news 0;

  &.first {
    margin-top: 0;
  }

  &.last {
    margin-bottom: 0;
  }
}





.dummydiv-right {
  display: none;
}

/***********************************************************************************/


.search-icon {
  cursor: pointer;
  height: 16px;
  margin: 0 0 0 20px;
  padding: 19px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 100px;
}

@media screen and (max-width: 1024px) {

  .welcome-bg {
    clip-path: polygon(0% 0%,100% 0%,100% 100%,0% calc(100% - 15px));

  }

  .welcome-container {
    background-image: linear-gradient(96deg, #f7a5cf, #ff50ee);//rgb(211, 68, 166));
    clip-path: polygon(0% 0%,100% 0%,100% 100%,0% calc(100% - 15px));

  }

  .home-category-container {
    margin-left: 20px;
  }

  .welcome-msg-container-desktop {
    display: none;
  }

  .welcome-msg-container-mobile {
    display: block;
    color: $white-color;
  }

  .home-body,
  .home-whoami .home-whoami-container {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .home-category-scroll-icon {
    display: block;
    height: 14px;
  }

  .welcome-msg {
    z-index: 3;
    padding-top: 20px;
    padding-bottom: 36px;
  }
  
  .welcome-title {
    font-size: 30px;
  }
  
  .welcome-subtitle {
    margin: 12px 0;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: normal;
    padding: 0px;
    background: none;
    width: auto;
  }

  .home-category {
    margin-bottom: 58px;
  }

  .advices {
    padding: 10px 0 10px 10px;
  }
}

@media screen and (max-width: 768px) {

  #h2-margined {
    margin-left: 20px;
  }

  .welcome-msg {
    padding-left: 20px;
    padding-right: 20px;
  }

  .home-body {
    width: 100%;
  }

  .home-category-scroll-icon {
    margin-right: 20px;
  }

   .home-whoami .home-whoami-container {
     flex-direction: column;
     width: 89%;
   }

  .home-whoami .home-whoami-container .home-whoami-img {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .dummydiv-right {
    display: block;
    min-width: 20px;
  }

  .dummydiv-right {
    min-width: 10px;
    background-color: transparent;
  }

  .question-introduction {
    padding: 0 20px;
    padding-top: 8px;
  }

  .question {
    padding: 0 20px;
  }

  .answers {
    margin-top: 22px;
    padding: 0 20px;
  }

  h2 {
    padding-left: 20px;
  }
}

@media screen and (max-width: 425px) {
  .answers {

    .answer-btn {
      margin: 6px 0
  
    }
  }

  .answers .btns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
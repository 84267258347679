@import 'colors';
@import 'dimensions';

.checkout-body {
  width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.checkout-body-container {
  display: flex;
}

.checkout-summary-mobile {
  display: none;
}

.checkout-summary-normal {
  height: auto;
  flex: 1;
  margin-left: 15px;
}

.form-container {
  display: flex;
  flex-direction: column;

  .block-input-line {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
}

.form-section-title,
.checkout-subtitle {
  font-family: AvenirNext;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  color: $grey-color;
}

.form-section-title {
  font-size: 15px;
  margin: 16px 0;

  &.without-top {
    margin-top: 0;
  }

  &.without-bottom {
    margin-bottom: 0;
  }
}

.checkout-subtitle {
  margin-bottom: 20px;
  font-size: 16px;
}

.checkout-body-step-container {
  flex: 1;
  min-width: 0;
  margin-right: 15px;
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  //border-radius: 0;
}

.checkout-next-step {
  //min-width: 0px;
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $fucsia-color;
  text-align: center;
  padding: 17px 50px;
  font-family: AvenirNext;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto;
  transition: all 100ms;

  &.disabled {
    opacity: 0.4;
  }
}

.checkout-next-step:hover {
  background-color: $fucsia-color-hover;
}

.checkout-next-step:active {
  background-color: $fucsia-color-active;
}

.checkout-summary-container {
  border-radius: 10px;
  box-shadow: 0 1.5px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 13px 13px;

  .checkout-summary-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;

    .checkout-summary-element-img-container {
      flex:1;
      min-width: 70px;

      .checkout-summary-element-img {
        border-radius: 5px;
      }
    }

    .checkout-summary-element-info {
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      margin-left: 8px;
      flex: 4;

      .checkout-summary-element-name {
        font-size: 15px;
        margin-right: 3px;
        margin-bottom: 5px;
        font-weight:500;
        color: $black-color;
      }
      .checkout-summary-element-details {
        display: flex;
        align-items: center;
        .checkout-summary-element-color {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: $grey-color;
        }

        .checkout-summary-element-dash {
          font-size: 12px;
          color: $grey-color;
          margin: 0 8px;
        }
  
        .checkout-summary-element-size {
          font-size: 12px;
          font-weight: 500;
          color: $grey-color;
        }
      }

      .checkout-summary-element-price {
        font-size: 16px;
        font-weight: 600;
        color: $black-color;
        white-space: nowrap;
      }
    }
  }

  .checkout-summary-subtotal,.checkout-summary-delivery,.checkout-summary-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    font-size: 14px;
    font-weight: normal;
    color: $black-color;
  }

  .checkout-summary-subtotal {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .checkout-summary-delivery {
    margin-bottom: 13px;
  }

  .checkout-summary-total {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
  }
}

.checkout-review-info-container {
  flex:1;
  padding: 19px 15.5px 19px 15px;
  border-radius: 14px;
  box-shadow: 0 1.5px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin-top: 20px;

  .checkout-review-info-category-container {
    display: flex;
    flex-direction: row;

    .checkout-review-info-category-title {
      width: 30%;
      font-size: 15px;
      letter-spacing: normal;
      color: $grey-color;
    }
  
    .checkout-review-info-category-values {
      width: 70%;
      margin-left: auto;
      font-size: 15px;
      color: $black-color;

      display: flex;
      flex-direction: column;
      overflow: scroll;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      
      .checkout-review-info-category-value {
        margin-bottom: 5px;

      }
    }

    .checkout-review-info-category-values::-webkit-scrollbar {
      display: none;
    }
  }

}

.circ-progress {
  margin: auto;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  font-size: 13px;
  color: $grey-color-unselected;
  font-weight: 300;

  &.active {
    color: $fucsia-color;
    font-weight: 500;
    cursor: pointer;
  }

  &.past {
    color: $fucsia-color-unselected;
    font-weight: 500;
    cursor: pointer;

  }
}

.step-arrow {
  height: 8px;
  margin: 0 8px;
}

.spinner {
  border: 3px solid #d3c7c8; /* Light grey */
  border-top: 3px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 17px;
  height: 17px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


//******************STRIPE ELEMENT*******************************

// .StripeElement { 
//   display: block;
//   margin: 10px 0 20px 0;
//   //max-width: 500px;
//   padding: 13px 14px;
//   font-size: 1em;
//   font-family: "Source Code Pro", monospace;
//   box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
//     rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
//   border: 0;
//   outline: 0;
//   border-radius: 4px;
//   background: white;
// }

@media screen and (max-width: 1024px) {

  .checkout-body {
    width: 95%;
    margin-left:auto;
    margin-right:auto;
  }

}

@media screen and (max-width: 768px) {

  .checkout-body {
    width: 100%;
    margin-left:auto;
    margin-right:auto;
  }

  .checkout-body-container {
    flex-direction: column;
  }

  .checkout-summary-normal {
    display: none;
    margin-left: 0;
  }

  .checkout-summary-mobile {
    display: block;

    .checkout-summary-mobile-container {
      overflow: hidden;
      padding: 0px 10px;
      transition: max-height 300ms;
      background-color: #f2f2f2;
    }

    .checkout-summary-mobile-btn {
      width: auto;
      background-color: #f2f2f2;
      color: $black-color;
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }

    .checkout-summary-container {
      padding: 11px 14px;
    }
  }

  .checkout-body-step-container {
    margin: 0 10px;
  }

  .form-container {
    margin-top: 20px;
    margin-right: 0;
  }

  .steps-container {
    margin-top: 13px;
  }

}

@media screen and (max-width: 500px) {

  // .shop-body {
  //   width: auto;
  //   margin: 0 10px;
  // }

  .form-container {
    margin-top: 20px;
    .block-input-line {
      flex-direction: column;
      align-items: normal;
    }
  }

  .checkout-input-container.left,
  .checkout-input-container.middle,
  .checkout-input-container.right {
    margin-right: 0;
    margin-left: 0;
  }

  .checkout-input-container.left {
    margin-bottom: 5px
  }

  .checkout-input-container.right {
    margin-top: 5px;
  }

  .checkout-input-container.middle {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .checkout-summary-container .checkout-summary-element .checkout-summary-element-info .checkout-summary-element-name {
    font-size: 14px;
  }

  .circ-progress {
    padding: 40% 0;
  }

}